.app {
    background-color: var(--primary-color);
    font-family: var(--font-base);
  }
  
  .app__whitebg {
    background-color: var(--white-color);
  }
  
  .app__primarybg {
    background-color: var(--primary-color);
  }
  
  .app__container {
    width: 100%;
    min-height: 100vh;
    // max-height: 99vh;
  
    display: flex;
    flex-direction: row;

    // @media screen and (max-width: 1300px) {
    //   width: 100%;
    //   min-height: 100vh;
    //   max-width: none;
    // }
  }
  
  .app__flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .app__wrapper {
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 4rem 2rem;
  
    @media screen and (max-width: 450px) {
      padding: 4rem 1rem 2rem;
    }
  }
  
  .copyright {
    width: 100%;
    padding: 2rem 0 0;
  
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  
    p {
      text-transform: uppercase;
      color: var(--black-color);
    }
  }
  
  .head-text {
    font-size: 2.75rem;
    font-weight: 800;
    text-align: center;
    color: var(--black-color);
    text-transform: capitalize;
  
    span {
      color: var(--secondary-color);
    }
  
    @media screen and (min-width: 2000px) {
      font-size: 4rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 2rem;
    }
  }
  
  .p-text {
    font-size: 0.8rem;
    text-align: left;
    color: var(--gray-color);
    line-height: 1.5;
  
    @media screen and (min-width: 2000px) {
      font-size: 1.75rem;
    }
  }

  .resume_button{
    position: absolute;
    left: 94%;
    width: auto;
    color: var(--secondary-color);
    white-space: nowrap;

    a{
      font-weight: 600;
    }

    &:hover {
      -webkit-animation-name: wiggle;
      -ms-animation-name: wiggle;
      -ms-animation-duration: 1000ms;
      -webkit-animation-duration: 1000ms;
      -webkit-animation-iteration-count: 1;
      -ms-animation-iteration-count: 1;
      -webkit-animation-timing-function: ease-in-out;
      -ms-animation-timing-function: ease-in-out;
    }
    
    @-webkit-keyframes wiggle {
      0% {-webkit-transform: rotate(0deg);}
      25% {-webkit-transform: rotate(-10deg);}
      50% {-webkit-transform: rotate(20deg);}
      75% {-webkit-transform: rotate(-5deg);}
      100% {-webkit-transform: rotate(0deg);}
    }
    
    @-ms-keyframes wiggle {
      0% {-ms-transform: rotate(0deg);}
      25% {-ms-transform: rotate(-1deg);}
      50% {-ms-transform: rotate(1.5deg);}
      75% {-ms-transform: rotate(-5deg);}
      100% {-ms-transform: rotate(0deg);}
    }
    
    @keyframes wiggle {
      0% {transform: rotate(0deg);}
      25% {transform: rotate(-10deg);}
      50% {transform: rotate(20deg);}
      75% {transform: rotate(-5deg);}
      100% {transform: rotate(0deg);}
    }

    @media screen and (min-width: 2000px) {
      left: 91%;
    }

    @media screen and (max-width: 1600px) {
      left: 92%;
    }

    @media screen and (max-width: 1400px) {
      left: 90%;
    }
  
    @media screen and (max-width: 450px) {
      left: 88%;
    }
    // padding: 0.5rem 2rem 1rem 2rem;
    // border: var(--secondary-color);
    // border-radius: 15px;
    // box-shadow: 0 0 20px rgba($color: #313bac, $alpha: 0.2);
}
  
  .bold-text {
    font-size: 1rem;
    font-weight: 800;
    color: var(--black-color);
    text-align: left;
  
    @media screen and (min-width: 2000px) {
      font-size: 2rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 0.9rem;
    }
  }
  
  .app__social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding: 1rem;

    margin-bottom: 100px;
    margin-left: 15px;

    div:nth-child(1) {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        svg {
          width: 25px;
          height: 25px;
        }
      }
  }
  
    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: var(--white-color);
      margin: 0.25rem 0;
      border: 2px solid var(--lightGray-color);
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      transition: all 0.3s ease-in-out;
  
      svg {
        width: 20px;
        height: 20px;
        color: var(--gray-color);
      }
  
      &:hover {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
  
        svg {
          color: var(--white-color);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 70px;
        height: 70px;
  
        margin: 0.5rem 0;
  
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  
  .app__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    padding: 1rem;
  
    .app__navigation-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #cbcbcb;
      margin: 0.5rem;
  
      transition: background-color 0.2s ease-in-out;
  
      &:hover {
        background-color: var(--secondary-color);
      }
  
      @media screen and (min-width: 2000px) {
        width: 20px;
        height: 20px;
      }
    }
  }
  
  @media screen and (max-width: 500px) {
    .app__navigation,
    .app__social {
      display: none;
    }
  
    .copyright {
      padding: 2rem;
    }
  }